import EmblaCarousel, {
  type EmblaCarouselType,
  type EmblaOptionsType,
} from 'embla-carousel'

const addDotBtnsAndClickHandlers = (
  emblaApi: EmblaCarouselType,
  dotsNode: HTMLElement,
): (() => void) => {
  let dotNodes: HTMLElement[] = []

  const addDotBtnsWithClickHandlers = (): void => {
    dotsNode.innerHTML = emblaApi
      .scrollSnapList()
      .map(() => '<button class="embla__dot" type="button"></button>')
      .join('')

    const scrollTo = (index: number): void => {
      emblaApi.scrollTo(index)
    }

    dotNodes = Array.from(dotsNode.querySelectorAll('.embla__dot'))
    dotNodes.forEach((dotNode, index) => {
      dotNode.addEventListener('click', () => scrollTo(index), false)
    })
  }

  const toggleDotBtnsActive = (): void => {
    const previous = emblaApi.previousScrollSnap()
    const selected = emblaApi.selectedScrollSnap()
    dotNodes[previous].classList.remove('embla__dot--selected')
    dotNodes[selected].classList.add('embla__dot--selected')
  }

  emblaApi
    .on('init', addDotBtnsWithClickHandlers)
    .on('reInit', addDotBtnsWithClickHandlers)
    .on('init', toggleDotBtnsActive)
    .on('reInit', toggleDotBtnsActive)
    .on('select', toggleDotBtnsActive)

  return (): void => {
    dotsNode.innerHTML = ''
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const emblaNodes = document.querySelectorAll('.embla')

  const optionsByClass: Record<string, EmblaOptionsType> = {
    'embla--testimonial': {},
    'embla--copywriting': { slidesToScroll: 'auto' },
  }

  for (const emblaNode of emblaNodes) {
    const viewportNode = <HTMLElement>(
      emblaNode.querySelector('.embla__viewport')
    )
    const dotsNode = <HTMLElement>emblaNode.querySelector('.embla__dots')

    const matchingClass = Object.keys(optionsByClass).find((className) =>
      emblaNode.classList.contains(className),
    )

    const OPTIONS = matchingClass ? optionsByClass[matchingClass] : {}

    const emblaApi = EmblaCarousel(viewportNode, OPTIONS)

    const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
      emblaApi,
      dotsNode,
    )

    emblaApi.on('destroy', removeDotBtnsAndClickHandlers)
  }
})
