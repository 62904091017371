document.addEventListener('DOMContentLoaded', () => {
  type DialogElements = {
    trigger: HTMLElement | null
    overlay: HTMLElement | null
    content: HTMLElement | null
    closeButtons: NodeListOf<HTMLElement>
  }

  type DialogState = {
    isOpen: boolean
    lastFocusedElement: HTMLElement | null
  }

  function createDialog(selector: string) {
    const elements: DialogElements = {
      trigger: document.querySelector(`${selector} [data-dialog-trigger]`),
      overlay: document.querySelector(`${selector} [data-dialog-overlay]`),
      content: document.querySelector(`${selector} [data-dialog-content]`),
      closeButtons: document.querySelectorAll(
        `${selector} [data-dialog-close]`,
      ),
    }

    if (!elements.trigger || !elements.overlay || !elements.content) {
      console.error('Dialog elements not found!')
      return {
        initialize: () => {},
        destroy: () => {},
        open: () => {},
        close: () => {},
      }
    }

    let state: DialogState = {
      isOpen: false,
      lastFocusedElement: null,
    }

    const updateDOMState = (): void => {
      const newState = state.isOpen ? 'open' : 'closed'

      elements.overlay?.setAttribute('data-state', newState)
      elements.content?.setAttribute('data-state', newState)
      elements.trigger?.setAttribute('aria-expanded', String(state.isOpen))

      if (elements.overlay) {
        elements.overlay.style.pointerEvents = state.isOpen ? 'auto' : 'none'
      }

      if (elements.content) {
        elements.content.style.pointerEvents = state.isOpen ? 'auto' : 'none'
      }

      if (state.isOpen) {
        document.body.style.overflow = 'hidden'
        elements.content?.focus()
        return
      }

      document.body.style.overflow = ''
      state.lastFocusedElement?.focus()
    }

    const trapFocus = (event: KeyboardEvent): void => {
      if (!state.isOpen || !elements.content) return
      if (event.key !== 'Tab') return

      const focusableElements = elements.content.querySelectorAll<HTMLElement>(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      )

      if (focusableElements.length === 0) return

      const firstElement = focusableElements[0]
      const lastElement = focusableElements[focusableElements.length - 1]

      if (event.shiftKey && document.activeElement === firstElement) {
        lastElement.focus()
        event.preventDefault()
        return
      }

      if (!event.shiftKey && document.activeElement === lastElement) {
        firstElement.focus()
        event.preventDefault()
      }
    }

    const openDialog = (): void => {
      state = {
        isOpen: true,
        lastFocusedElement: document.activeElement as HTMLElement,
      }
      updateDOMState()
      document.addEventListener('keydown', handleKeyDown)
    }

    const closeDialog = (): void => {
      state = { ...state, isOpen: false }
      updateDOMState()
      document.removeEventListener('keydown', handleKeyDown)
    }

    const handleTriggerClick = (event: MouseEvent): void => {
      event.preventDefault()
      openDialog()
    }

    const handleOverlayClick = (event: MouseEvent): void => {
      const isClickOutside = !elements.content?.contains(event.target as Node)
      if (isClickOutside) closeDialog()
    }

    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') closeDialog()
      trapFocus(event)
    }

    const initialize = (): void => {
      updateDOMState()

      elements.trigger?.addEventListener('click', handleTriggerClick)
      elements.overlay?.addEventListener('click', handleOverlayClick)

      for (const button of elements.closeButtons) {
        button.addEventListener('click', closeDialog)
      }

      if (elements.content) {
        elements.content.tabIndex = -1
      }
    }

    const destroy = (): void => {
      elements.trigger?.removeEventListener('click', handleTriggerClick)
      elements.overlay?.removeEventListener('click', handleOverlayClick)

      for (const button of elements.closeButtons) {
        button.removeEventListener('click', closeDialog)
      }

      document.removeEventListener('keydown', handleKeyDown)
    }

    return { initialize, destroy, open: openDialog, close: closeDialog }
  }

  const dialog = createDialog('[data-dialog]')
  dialog.initialize()
})
