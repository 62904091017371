document.addEventListener('DOMContentLoaded', () => {
  const videoSection = document.querySelector<HTMLElement>('.js-video-section')
  const video = document.querySelector<HTMLVideoElement>(
    '.js-video-section__video',
  )

  videoSection?.addEventListener('click', () => {
    if (!video) return

    if (!video.paused) {
      video.pause()
      return
    }

    video.play()
  })
})
