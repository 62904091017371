document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll<HTMLElement>('.accordion-root')
    .forEach((root, rootIndex) => {
      root
        .querySelectorAll<HTMLElement>('.accordion')
        .forEach((accordion, index) => {
          const trigger =
            accordion.querySelector<HTMLElement>('.accordion-trigger')
          const button = trigger?.querySelector<HTMLButtonElement>('button')
          const content =
            accordion.querySelector<HTMLElement>('.accordion-content')

          if (!trigger || !button || !content) return

          const uniqueId = `accordion-${rootIndex}-${index}`
          const buttonId = `${uniqueId}-trigger`
          const contentId = `${uniqueId}-content`

          button.id = buttonId
          button.setAttribute('aria-controls', contentId)
          content.id = contentId
          content.setAttribute('aria-labelledby', buttonId)

          trigger.addEventListener('click', () => {
            const isOpen = accordion.getAttribute('data-state') === 'open'

            for (const acc of root.querySelectorAll<HTMLElement>(
              '.accordion',
            )) {
              acc.setAttribute('data-state', 'closed')
              acc
                .querySelector<HTMLButtonElement>('.accordion-trigger button')
                ?.setAttribute('aria-expanded', 'false')
              const accContent =
                acc.querySelector<HTMLElement>('.accordion-content')
              if (accContent) {
                accContent.style.height = '0px'
                accContent.style.opacity = '0'
                accContent.style.overflow = 'hidden'
              }
            }

            if (!isOpen) {
              accordion.setAttribute('data-state', 'open')
              button.setAttribute('aria-expanded', 'true')
              content.style.height = `${content.scrollHeight}px`
              content.style.opacity = '1'
              content.style.overflow = 'visible'
            }
          })
        })
    })
})
